<template>
    <div
        v-if="settings.is_sourcing_mode"
        class="inline-flex items-center justify-between"
        :class="[
            loading || inert || disabled ? 'opacity-50' : '',
            {
                // Round
                'border border-gray h-[50px] rounded-full': round,
                // Round and not inverted
                'bg-white text-gray-675 dark:bg-gray-800 dark:text-gray-275': round && !invert,
                // Round and inverted
                'bg-gray-800 text-gray-275 dark:bg-white dark:text-gray-675': round && invert,
                // Default (not round, not inverted)
                'duration-200 at768:justify-center h-full gap-1 mx-auto': !round
            }
        ]"
    >
        <button
            @click="computedValue--"
            class="w-10 disabled:grayscale disabled:opacity-50 disabled:pointer-events-none"
            :class="{
                /// Round
                'h-full rounded-r-full': round,
                // Round and not inverted
                'hover:text-black dark:hover:text-white': round && !invert,
                // Round and inverted
                'hover:text-white dark:hover:text-black': round && invert,
                // Default (not round, not inverted)
                'btn-app-icon-black-on-gray h-10 flex items-center justify-center': !round
            }"
            :disabled="computedValue <= 1 || inert || loading || disabled"
        >
            <BaseSvg name="icon-subtraction-sign" aria-hidden="true" />
            <span class="sr-only">Decrease quantity</span>
        </button>
        <loading-spinner v-if="loading" class="mx-4 my-4"/>
        <input
            v-else
            type="text"
            ref="input"
            v-model="computedValue"
            @focusout="handleFocusOut()"
            class="text-center"
            :class="{
                // Round
                'bg-transparent border-none font-semibold text-lg w-14': round,
                // Round and not inverted
                '': round && !invert,
                // Round and inverted
                'text-white dark:text-black': round && invert,
                // Default (not round, not inverted)
                'border-keyline-primary type-primary bg-gray-tier-2 h-10 w-12 rounded': !round
            }"
            :disabled="inert || disabled || loading"
            aria-label="Quantity"
        />
        <button
            @click="computedValue++"
            :disabled="inert || loading || disabled"
            class="w-10 disabled:grayscale disabled:opacity-50 disabled:pointer-events-none"
            :class="{
                // Round
                'h-full rounded-r-full': round,
                // Round and not inverted
                'hover:text-black dark:hover:text-white': round && !invert,
                // Round and inverted
                'hover:text-white dark:hover:text-black': round && invert,
                // Default (not round, not inverted)
                'btn-app-icon-black-on-gray h-10 flex items-center justify-center': !round
            }"
        >
            <BaseSvg name="icon-addition-sign" aria-hidden="true"/>
            <span class="sr-only">Increase quantity</span>
        </button>
    </div>
    <div
        v-else
        :class="inert || disabled || loading ? 'opacity-50' : ''"
        class="duration-200 inline-flex items-center justify-between at768:justify-center h-full border border-gray-300 rounded-sm focus-within:border-blue focus-within:ring-1 ring-blue print:!h-auto"
    >
        <button
            @click="computedValue--"
            type="button"
            :class="
                computedValue === 1
                    ? 'opacity-25 hover:bg-transparent active:bg-transparent'
                    : 'hover:bg-black/5 active:bg-black/10'
            "
            class="flex items-center justify-center w-8 h-full print:hidden"
            :disabled="computedValue <= 1 || inert || loading || disabled"
        >
            <BaseSvg name="icon-subtraction-sign" aria-hidden="true" />
            <span class="sr-only">Decrease quantity</span>
        </button>
        <loading-spinner v-if="loading" class="mx-4 my-4"/>
        <input
            v-else
            @focusout="handleFocusOut()"
            type="text"
            ref="input"
            v-model="computedValue"
            :disabled="inert || disabled || loading"
            class="w-12 h-10 border-none text-center bg-transparent focus:outline-none focus:ring-0 print:h-auto print:p-1 print:text-xs"
            aria-label="Quantity"
        />
        <button
            @click="computedValue++"
            type="button"
            :disabled="inert || loading || disabled"
            class="flex items-center justify-center w-8 h-full hover:bg-black/5 active:bg-black/10 py-1 print:hidden"
        >
            <BaseSvg name="icon-addition-sign" aria-hidden="true"/>
            <span class="sr-only">Increase quantity</span>
        </button>
    </div>
</template>

<script setup>
import { BaseSvg, LoadingSpinner } from "@/Components";
import { useUser } from "@/Composables";
import { computed, ref } from "vue";

const { settings } = useUser();
const props = defineProps({
    modelValue: {
        type: Number,
        required: true,
        default: 1,
    },
    contrast: {
        type: Boolean,
        required: false,
        default: false,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    loading: {
        type: Boolean,
        required: false,
        default: false,
    },
    round: {
        type: Boolean,
        required: false,
        default: false,
    },
    invert: {
        type: Boolean,
        required: false,
        default: false,
    },
});
const emit = defineEmits(["update:modelValue"]);

const handleFocusOut = () => {
    if(input.value.value === '') {
        input.value.value = 1;
        computedValue.value = 1;
    }
}

const input = ref(null);

const computedValue = computed({
    get() {
        return props.modelValue;
    },
    set(newValue) {
        if(isNaN(newValue) || newValue < 1) {
            newValue = input.value.value = '';
        }
        if(newValue >= 1) {
            emit("update:modelValue", Math.abs(newValue));
        }
    },
});

let intervalId = null;

function startIncrement(isIncreasing) {
    if (intervalId === null) {  // Only set interval if it's not already set
        intervalId = setInterval(() => {
            isIncreasing ? computedValue.value++ : computedValue.value--;
            input.value.value = computedValue.value;
        }, 125);  // Adjust the interval time as needed
    }
}

// Function to stop incrementing
function stopIncrement() {
    if (intervalId !== null) {
        clearInterval(intervalId);
        intervalId = null;
    }
}
</script>
