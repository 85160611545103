import { DateTime } from "luxon";

const today = DateTime.now();

export default {
    alerts: {
        zindex: 50,
        levels: {
            danger: {
                icon: "icon-error-outline-lg",
                iconColorClass: "text-white",
                bgColorClass: "bg-red",
                textColorClass: "text-white",
            },
            warning: {
                icon: "icon-error-outline-lg",
                iconColorClass: "text-white",
                bgColorClass: "bg-orange",
                textColorClass: "text-white",
            },
            success: {
                icon: "icon-check-outlined-lg",
                iconColorClass: "text-green",
                bgColorClass: "bg-gray-700",
                textColorClass: "text-white",
            },
            info: {
                icon: "icon-light-bulb-lg",
                iconColorClass: "text-white",
                bgColorClass: "bg-blue",
                textColorClass: "text-white",
            },
            debug: {
                icon: "icon-check",
                iconColorClass: "text-white",
                bgColorClass: "bg-gray",
                textColorClass: "text-white",
            },
        },
    },
    branding: {
        options: [
            {
                label: "option-1-label",
                value: "option-1-value",
            },
            {
                label: "option-2-label",
                value: "option-2-value",
            },
            {
                label: "option-3-label",
                value: "option-3-value",
            },
        ],
        alerts: [
            {
                title: "Danger",
                message: "format/disable this in js/Components/Alerts",
                level: "danger",
                persist: true,
            },
            {
                title: "Warning",
                message: "format/disable this in js/Components/Alerts",
                level: "warning",
                persist: false,
            },
            {
                title: "Success",
                message: "format/disable this in js/Components/Alerts",
                level: "success",
                persist: true,
            },
            {
                title: "Info",
                message: "format/disable this in js/Components/Alerts",
                level: "info",
                persist: false,
            },
        ],
    },
    underlay: {
        zindex: 50,
        keys: [
            "ArrowUp",
            "ArrowUp",
            "ArrowDown",
            "ArrowDown",
            "ArrowLeft",
            "ArrowRight",
            "ArrowLeft",
            "ArrowRight",
            "b",
            "a",
        ],
    },
    dialogs: {
        navigation: {
            zindex: 20,
            searchDialog: {
                zindex: 100
            },
        },
        modals: {
            zindex: 50,
        },
        drawers: {
            zindex: 40,
            productSearchDialog: {
                zindex: 50,
            },
            pinsDialog: {
                zindex: 31,
                underHeader: true,
            },
            notificationsDialog: {
                zindex: 31,
                underHeader: true,
            },
        },
    },
    sourcingLinks: [
        {
            title: "Insights",
            name: "insights",
            route: "web.insights.index",
            route_check: "web.insights.*",
            icon: "icon-insights-outline-thick",
            icon_active: "icon-insights-outline-thick",
            role: "Employee",
            sublinks: [
                {
                    title: "Web Daily Sales",
                    name: "insights",
                    route: "web.insights.index",
                },
                {
                    title: "Sales Metrics",
                    name: "insights",
                    route: "https://lookerstudio.google.com/u/0/reporting/032ece3c-21ef-4e7e-9bec-6dce3bfa4c3c/page/vEsdC", // outside link todo:where is this supposed to go?
                    target: true,
                },
                // {
                //     title: "Purchasing Metrics",
                //     name: "insights",
                //     route: "web.insights.purchasing-metrics.index",
                // },
                {
                    title: "Vendors",
                    name: "insights",
                    route: "web.insights.vendors.index",
                },
                {
                    title: "Product ID",
                    name: "insights",
                    route: "web.insights.product-id.index",
                },
                {
                    title: "Order Search",
                    name: "insights",
                    route: "web.insights.orders.index",
                },
                {
                    title: "Quote Search",
                    name: "insights-quotes",
                    route: "web.insights.quotes.index",
                },
                {
                    title: "Priority Builds",
                    name: "priority-builds",
                    route: "web.insights.priority-builds.index",
                },
            ],
        },
        {
            title: "SKU Manager",
            name: "sku-sets",
            role: "SKU Sets",
            route: "web.account.sku-sets.index",
            route_check: "web.account.sku-sets.*",
            icon: "icon-barcode-outline-thick",
            icon_active: "icon-barcode-outline-thick",
        },
        {
            title: "Projects",
            name: "projects",
            route: "web.account.projects.index",
            route_check: "web.account.projects.*",
            icon: "icon-projects-outline-thick",
            icon_active: "icon-projects-filled-thick",
        },
        {
            title: "Sales",
            name: "sales",
            route: "web.account.sales.orders.index",
            route_check: "web.account.sales.*",
            icon: "icon-sales-outline-thick",
            icon_active: "icon-sales-filled-thick",
            sublinks: [
                {
                    title: "Orders",
                    name: "sales",
                    route: "web.account.sales.orders.index",
                },
                {
                    title: "Invoices",
                    name: "sales",
                    route: "web.account.sales.invoices.index",
                },
                {
                    title: "Quotes",
                    name: "sales",
                    route: "web.account.sales.quotes.index",
                },
                {
                    title: "Reports",
                    name: "sales",
                    route: "web.account.sales.reports.index",
                },
            ],
        },
        {
            title: "Products",
            name: "products",
            route: "web.catalog.products.index",
            route_check: "web.catalog.products.*",
            icon: "icon-products-outline-thick",
            icon_active: "icon-products-filled-thick",
        },
        {
            title: "Manufacturers",
            name: "manufacturers",
            route: "web.catalog.manufacturers.index",
            route_check: "web.catalog.manufacturers.*",
            icon: "icon-manufacturers-outline-thick",
            icon_active: "icon-manufacturers-filled-thick",
        },
        {
            title: "Resources",
            name: "resources",
            route: "web.catalog.resources.index",
            route_check: "web.catalog.resources.*",
            icon: "icon-resources-outline-thick",
            icon_active: "icon-resources-filled-thick",
            sublinks: [
                {
                    title: "Manufacturer Literature",
                    name: "resources",
                    route: "web.catalog.resources.index",
                },
                {
                    title: "List Price Books",
                    name: "resources",
                    route: "web.catalog.price-books.index",
                },
            ],
        },
        {
            title: "Accounts",
            name: "accounts",
            role: "employee",
            route: "web.accounts.companies.index",
            route_check: "web.accounts.*",
            icon: "icon-accounts-outline-thick",
            icon_active: "icon-accounts-filled-thick",
            sublinks: [
                {
                    title: "Companies",
                    name: "accounts",
                    route: "web.accounts.companies.index",
                },{
                    title: "Users",
                    name: "accounts",
                    route: "web.accounts.users.index",
                },
            ],
        },
    ],
    account: {
        links: [
            {
                route: "web.account.dashboard",
                baseRoute: "dashboard",
                text: "Dashboard",
            },
            {
                route: "web.account.profile.show",
                baseRoute: "profile",
                text: "Profile",
            },
            {
                route: "web.account.sales.orders.index",
                baseRoute: "orders",
                text: "Orders",
            },
            {
                route: "web.account.sales.quotes.index",
                baseRoute: "quotes",
                text: "Quotes",
            },
            {
                route: "web.account.sales.invoices.index",
                baseRoute: "invoices",
                text: "Invoices",
            },
            {
                route: "web.account.sales.reports.index",
                baseRoute: "reports",
                text: "Reports",
            },
            {
                route: "web.account.favorites.index",
                baseRoute: "favorites",
                text: "Favorites",
                marketing_only: true,
            },
            {
                route: "web.account.saved-carts.index",
                baseRoute: "saved-carts",
                text: "Saved Carts",
            },
            {
                route: "web.account.returns.index",
                baseRoute: "returns",
                text: "Returns",
            },
            {
                route: "web.account.addresses.index",
                baseRoute: "addresses",
                text: "Addresses",
            },
            {
                route: "web.account.sku-sets.index",
                baseRoute: "sku-sets",
                text: "SKU Sets",
                roles: ['Developer', 'Employee','MAPI User', 'SKU Sets'],
            },
            {
                route: "web.account.credit-reference",
                baseRoute: "credit-reference",
                text: "Credit Reference",
            },
            {
                route: "web.account.my-products.index",
                baseRoute: "my-products",
                text: "My Products",
            },
            // {
            //     route: "web.account.teams.index",
            //     baseRoute: "teams",
            //     text: "Team",
            // },
        ],
    },
    locations: [
        {
            code: "01",
            id: "map_ma",
            address: "25 Dartmouth Street",
            city: "Westwood",
            state: "MA",
            zip: "02090",
            phone: "800-847-5625",
            directions:
                "https://www.google.com/maps/dir//25+Dartmouth+St,+Westwood,+MA+02090/@42.2040483,-71.2336022,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89e48031822464f5:0x1a0d1e99149f40be!2m2!1d-71.1512031!2d42.2040778?hl=en",
            pickup: true,
            hours: "M-F, 8:00am-5:30pm EST",
            map: {
                options: {
                    center: {
                        lat: 42.204383,
                        lng: -71.150976,
                    },
                    streetViewControl: false,
                    scrollwheel: false,
                    zoom: 15,
                    mapId: "8c2f30f62f085ed3",
                },
                markers: [
                    {
                        lat: 42.204383,
                        lng: -71.150976,
                        title: "",
                        info: "<p>25 Dartmouth St<br />Westwood, MA 02090<br /><strong>800-847-5625</strong></p>",
                    },
                ],
            },
        },
        {
            code: "33",
            id: "map_il",
            address: "640 Heathrow Drive",
            city: "Lincolnshire",
            state: "IL",
            zip: "60069",
            phone: "847-520-2508",
            directions:
                "https://www.google.com/maps/dir//640+Heathrow+Dr,+Lincolnshire,+IL+60069/@42.1866879,-87.9479487,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x880fbdcf09ddbb05:0x70f5323604663c57!2m2!1d-87.94576!2d42.1866879?hl=en",
            pickup: true,
            hours: "M-F, 8:00am–5:30pm CST",
            map: {
                options: {
                    center: {
                        lat: 42.1867781,
                        lng: -87.9457593,
                    },
                    streetViewControl: false,
                    scrollwheel: false,
                    zoom: 15,
                    mapId: "8c2f30f62f085ed3",
                },
                markers: [
                    {
                        lat: 42.1867781,
                        lng: -87.9457593,
                        title: "",
                        info: "<p>640 Heathrow Drive<br />Lincolnshire, IL 60069<br /><strong>847-520-2508</strong></p>",
                    },
                ],
            },
        },
        {
            code: "22",
            id: "map_nv",
            address: "5785 N Hollywood Blvd",
            city: "Las Vegas",
            state: "NV",
            zip: "89115",
            phone: "702-434-6866",
            directions:
                "https://www.google.com/maps/dir//5785+N+Hollywood+Blvd,+Las+Vegas,+NV+89115/@36.2643776,-115.0268397,18z/data=!4m9!4m8!1m0!1m5!1m1!1s0x80c8e766e256f057:0x11ff866fd742ef61!2m2!1d-115.0259552!2d36.2644691!3e0?entry=ttu",
            pickup: true,
            hours: "M-F, 8:00am–4:30pm PST",
            map: {
                options: {
                    center: {
                        lat: 36.2617701,
                        lng: -115.0273782,
                    },
                    streetViewControl: false,
                    scrollwheel: false,
                    zoom: 15,
                    mapId: "8c2f30f62f085ed3",
                },
                markers: [
                    {
                        lat: 36.2617701,
                        lng: -115.0273782,
                        title: "",
                        info: "<p>5785 N Hollywood Blvd<br />Las Vegas, NV 89115<br /><strong>702-434-6866</strong></p>",
                    },
                ],
            },
        },
        {
            code: "11",
            id: "map_fl",
            address: "2250-A NW 15th Avenue",
            city: "Pompano Beach",
            state: "FL",
            zip: "33069",
            phone: "954-972-4949",
            directions:
                "https://www.google.com/maps?ll=26.258723,-80.143118&z=13&t=m&hl=en&gl=US&mapclient=embed&daddr=2250+NW+15th+Ave+Pompano+Beach,+FL+33069@26.258723,-80.143118",
            pickup: true,
            hours: "M-F, 8:30am–4:30pm EST",
            map: {
                options: {
                    center: {
                        lat: 26.2587608,
                        lng: -80.1431212,
                    },
                    streetViewControl: false,
                    scrollwheel: false,
                    zoom: 15,
                    mapId: "8c2f30f62f085ed3",
                },
                markers: [
                    {
                        lat: 26.2587608,
                        lng: -80.1431212,
                        title: "",
                        info: "<p>22250-A NW 15th Avenue<br />Pompano Beach, FL 33069<br /><strong>954-972-4949</strong></p>",
                    },
                ],
            },
        },
    ],
    westcoast: {
        id: "map_wc",
        address: "5785 N Hollywood Blvd",
        city: "Las Vegas",
        state: "NV",
        zip: "89115",
        phone: null,
        directions:
            "https://www.google.com/maps/place/5785+N+Hollywood+Blvd,+Las+Vegas,+NV+89115/@36.2639327,-115.0300643,17z/data=!4m6!3m5!1s0x80c8e766e256f057:0x11ff866fd742ef61!8m2!3d36.2644691!4d-115.0259552!16s%2Fg%2F11c873v5s9?entry=ttu",
        pickup: true,
        hours: "M-F, 8:00am-5:30pm PST",
        map: {
            options: {
                center: {
                    lat: 36.264629000811446,
                    lng: -115.02567615361623
                },
                streetViewControl: false,
                scrollwheel: false,
                zoom: 15,
                mapId: "8c2f30f62f085ed3",
            },
            markers: [
                {
                    lat: 36.264629000811446,
                    lng: -115.02567615361623,
                    title: "",
                    info: "<p>5785 N Hollywood Blvd<br />Las Vegas, NV 89115</strong></p>",
                },
            ],
        },
    },
    contact: {
        map: {
            options: {
                center: { lat: 39.0997, lng: -94.5786 },
                streetViewControl: false,
                scrollwheel: false,
                zoom: 4,
                mapId: "8c2f30f62f085ed3",
                zoomControl: true,
                mapTypeControl: true,
            },
        },
    },
    layout: {},
    alphabet: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
    ],
    months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ],
    shippingMethods: {
        Standard: [
            {
                "label": "Ground",
                "value": "GROUND", //<-- The actual code value is computed in the CheckoutController for UPS(UGND)or FEDEX(FG)
                "carrier": "ups",
                "type": null,
                "destination": null,
            },
        ],
        UPS: [
            {
                "label": "UPS 3 Day Select",
                "value": "U3DS",
                "carrier": "ups",
                "type": null,
                "destination": null,
            },
            {
                "label": "UPS 2nd Day Air",
                "value": "U2ND",
                "carrier": "ups",
                "type": null,
                "destination": null,
            },
            {
                "label": "UPS Next Day Air",
                "value": "UNXT",
                "carrier": "ups",
                "type": null,
                "destination": null,
            },
            {
                "label": "UPS Next Day Air Saver",
                "value": "UNDS",
                "carrier": "ups",
                "type": null,
                "destination": null,
            },
            {
                "label": "UPS NEXT DAY AIR EARLY AM",
                "value": "UNAM",
                "carrier": "ups",
                "type": null,
                "destination": null,
            },
            {
                "label": "UPS NEXT DAY AIR SATURDAY",
                "value": "UNXTS",
                "carrier": "ups",
                "type": null,
                "destination": null,
            },
            {
                "label": "UPS GROUND CONSIGNEE",
                "value": "UGNDC",
                "carrier": "ups",
                "type": "cons",
                "destination": null,
            },
            {
                "label": "UPS 3 DAY SELECT CONSIGNEE",
                "value": "U3DSC",
                "carrier": "ups",
                "type": "cons",
                "destination": null,
            },
            {
                "label": "UPS 2ND DAY AIR CONSIGNEE",
                "value": "U2NDC",
                "carrier": "ups",
                "type": "cons",
                "destination": null,
            },
            {
                "label": "UPS NEXT DAY AIR CONSIGNEE",
                "value": "UNXTC",
                "carrier": "ups",
                "type": "cons",
                "destination": null,
            },
            {
                "label": "UPS NEXT DAY AIR SAVER CONSIGNEE",
                "value": "UNDSC",
                "carrier": "ups",
                "type": "cons",
                "destination": null,
            },
            {
                "label": "UPS NEXT DAY AIR EARLY AM CONSIGNEE",
                "value": "UNAMC",
                "carrier": "ups",
                "type": "cons",
                "destination": null,
            },
            {
                "label": "UPS STANDARD TO CANADA",
                "value": "USTC",
                "carrier": "ups",
                "type": "cons",
                "destination": "canada",
            },
            {
                "label": "UPS STANDARD TO CANADA CONSIGNEE",
                "value": "USTCC",
                "carrier": "ups",
                "type": "cons",
                "destination": "canada",
            },
            {
                "label": "UPS WORLDWIDE EXPRESS",
                "value": "UWE",
                "carrier": "ups",
                "type": "cons",
                "destination": "canada",
            },
            {
                "label": "UPS WORLDWIDE EXPRESS CONSIGNEE",
                "value": "UWEC",
                "carrier": "ups",
                "type": "cons",
                "destination": "canada",
            },
        ],
        FEDEX: [
            {
                "label": "FEDEX 2 Day AM",
                "value": "F2A",
                "carrier": "fedex",
                "type": null,
                "destination": null,
            },
            {
                "label": "FEDEX 2 Day",
                "value": "F2D",
                "carrier": "fedex",
                "type": null,
                "destination": null,
            },
            {
                "label": "FEDEX Express Saver",
                "value": "FES",
                "carrier": "fedex",
                "type": null,
                "destination": null,
            },
            {
                "label": "FEDEX Standard Overnight",
                "value": "FSO",
                "carrier": "fedex",
                "type": null,
                "destination": null,
            },
            {
                "label": "FEDEX Priority Overnight",
                "value": "FPO",
                "carrier": "fedex",
                "type": null,
                "destination": null,
            },
            {
                "label": "FEDEX First Overnight",
                "value": "FFO",
                "carrier": "fedex",
                "type": null,
                "destination": null,
            },
            {
                "label": "FEDEX Next Day First Saturday",
                "value": "FFOSAT",
                "carrier": "fedex",
                "type": null,
                "destination": null,
            },
            {
                "label": "FEDEX Next Day AM Saturday",
                "value": "FPOSAT",
                "carrier": "fedex",
                "type": null,
                "destination": null,
            },
            {
                "label": "FEDEX Next Day PM Saturday",
                "value": "FSOSAT",
                "carrier": "fedex",
                "type": null,
                "destination": null,
            },
            {
                "label": "FEDEX GROUND CONSIGNEE",
                "value": "FGC",
                "carrier": "fedex",
                "type": "cons",
                "destination": null,
            },
            {
                "label": "FEDEX EXPRESS SAVER CONSIGNEE",
                "value": "FESC",
                "carrier": "fedex",
                "type": "cons",
                "destination": null,
            },
            {
                "label": "FEDEX 2ND DAY AIR CONSIGNEE",
                "value": "F2DC",
                "carrier": "fedex",
                "type": "cons",
                "destination": null,
            },
            {
                "label": "FEDEX STD OVERNIGHT CONSIGNEE",
                "value": "FSOC",
                "carrier": "fedex",
                "type": "cons",
                "destination": null,
            },
            {
                "label": "FEDEX PRIORITY OVERNIGHT CONSIGNEE",
                "value": "FPOC",
                "carrier": "fedex",
                "type": "cons",
                "destination": null,
            },
            {
                "label": "FEDEX INTL ECONOMY CONSIGNEE",
                "value": "FIEC",
                "carrier": "fedex",
                "type": "cons",
                "destination": null,
            },
            {
                "label": "FEDEX INTL FIRST CONSIGNEE",
                "value": "FIFC",
                "carrier": "fedex",
                "type": "cons",
                "destination": null,
            },
            {
                "label": "FEDEX INTL PRIORITY CONSIGNEE",
                "value": "FIPC",
                "carrier": "fedex",
                "type": "cons",
                "destination": null,
            },
        ],
        Pickup: [
            {
                "label": "Pickup - Westwood, MA",
                "value": "PKUP01",
                "carrier": "customer",
                "type": null,
                "destination": null,
            },
            {
                "label": "Pickup - Pompano Beach, FL",
                "value": "PKUP11",
                "carrier": "customer",
                "type": null,
                "destination": null,
            },
            {
                "label": "Pickup - Las Vegas, NV",
                "value": "PKUP22",
                "carrier": "customer",
                "type": null,
                "destination": null,
            },
            {
                "label": "Pickup - Lincolnshire, IL",
                "value": "PKUP33",
                "carrier": "customer",
                "type": null,
                "destination": null,
            },
        ],
    },
    countries: [
        {
            label: "United States",
            value: "US",
        },

        {
            label: "Canada",
            value: "CA",
        },
    ],
    states: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
        "Puerto Rico",
        "Virgin Islands",
        "Alberta",
        "British Columbia",
        "Manitoba",
        "New Brunswick",
        "Newfoundland and Labrador",
        "Nova Scotia",
        "Ontario",
        "Prince Edward Island",
        "Quebec",
        "Saskatchewan",
        "Northwest Territories",
        "Nunavut",
        "Yukon",
        "AB",
        "BC",
        "MB",
        "NB",
        "NL",
        "NT",
        "NS",
        "NU",
        "ON",
        "PE",
        "QC",
        "SK",
        "YT",
    ],
    miscellaneousLinks: [
        {
            name: "Contact",
            route: "contact",
        },
        {
            name: "Locations",
            route: "locations",
        },
        {
            name: "Careers",
            route: "careers",
        },
        {
            name: "Webinars",
            route: "webinars",
        },
        // {
        //     name: 'Developers',
        //     route: 'developers',
        // },
    ],
    catalogOptions: [
        {
            title: "Products",
            description: "Search by product type with filter options",
            route: "web.catalog.products.index",
            icon: "icon-products-outline",
        },
        {
            title: "Manufacturers",
            description: "Browse products by manufacturer series",
            route: "web.catalog.manufacturers.index",
            icon: "icon-manufacturers-outline",
        },
        {
            // title: 'List Price Books & Catalogs',
            title: "Price Books & Catalogs",
            description: "Access pricing and catalogs for all brands we stock",
            route: "web.catalog.price-books.index",
            icon: "icon-catalogs-book-outline",
        },
        {
            title: "Resources",
            description: "Browse our library of manufacturer docs & resources",
            route: "web.catalog.resources.index",
            icon: "icon-resources-outline",
        },
        {
            title: "Value Center",
            description: "Selection of discontinued, overstock, and closeouts",
            route: "web.catalog.tags.value-center",
            icon: "icon-resources-outline",
        },
    ],
    catalogSegments: [
        {
            title: "Healthcare",
            route: "web.catalog.tags.healthcare",
        },
        {
            title: "Multi-Family",
            route: "web.catalog.tags.multifamily",
        },
        {
            title: "Touchless",
            route: "web.catalog.tags.touchless",
        },
    ],
    supportOptions: [
        {
            title: "Get Help",
            route: "https://support.seclock.com",
            target: "_blank"
        },
        {
            title: "Locations",
            route: "web.locations",
        },
        {
            title: "Contact",
            route: "web.contact",
        },
    ],
    supportSegments: [
        {
            title: "Locations",
            route: "web.locations",
        },
        {
            title: "Webinars",
            route: "web.webinars",
        },
        {
            title: "Careers",
            route: "web.careers.index",
        },
    ],
    socialLinks: [
        {
            title: "Facebook",
            svg: "icon-facebook",
            link: "https://www.facebook.com/pages/Security-Lock-Distributors/122936424416981",
        },
        {
            title: "Twitter",
            svg: "icon-twitter",
            link: "https://twitter.com/seclock",
        },
        {
            title: "LinkedIn",
            svg: "icon-linkedin",
            link: "https://www.linkedin.com/company/2380043",
        },
        {
            title: "Youtube",
            svg: "icon-youtube",
            link: "https://www.youtube.com/channel/UCja3JyZ9S8bX6QteouDFwCg",
        },
    ],
    accountOptions: [
        {
            title: "Profile",
            route: "web.account.profile.show",
        },
        {
            title: "Dashboard",
            route: "web.account.dashboard",
        },
        {
            title: "Orders",
            route: "web.account.sales.orders.index",
        },
        {
            title: "Invoices",
            route: "web.account.sales.invoices.index",
        },
        {
            title: "Quotes",
            route: "web.account.sales.quotes.index",
        },
        {
            title: "Reports",
            route: "web.account.sales.reports.index",
        },
        {
            title: "Favorites",
            route: "web.account.favorites.index",
        },
        {
            title: "Saved Carts",
            route: "web.account.saved-carts.index",
        },
        {
            title: "Addresses",
            route: "web.account.addresses.index",
        },
        {
            title: "Returns",
            route: "web.account.returns.index",
        },
        {
            title: "SKU Sets",
            route: "web.account.sku-sets.index",
        },
        // {
        //     title: "Team",
        //     route: "web.account.teams.index",
        // },
        {
            title: "Allegion Order Form",
            route: "web.cart.allegion",
        },
        {
            title: "Credit Reference",
            route: "web.account.credit-reference",
        },
        {
            title: "My Products",
            route: "web.account.my-products.index",
        },
    ],
    datepicker: {
        presets: [
            {
                label: 'Last 30 Days',
                selected: true,
                startDate: today.minus({days: 30}).toJSDate(),
                endDate: today.toJSDate(),
            },
            {
                label: 'This Month',
                selected: false,
                startDate: today.startOf('month').toJSDate(),
                endDate: today.toJSDate(),
            },
            {
                label: 'Last Month',
                selected: false,
                startDate: today.minus({ months: 1 }).startOf('month').toJSDate(),
                endDate: today.minus({ months: 1 }).endOf('month').toJSDate(),
            },
            {
                label: '3 Months',
                selected: false,
                startDate: today.minus({ months: 3 }).toJSDate(),
                endDate: today.toJSDate(),
            },
            {
                label: '6 Months',
                selected: false,
                startDate: today.minus({ months: 6 }).toJSDate(),
                endDate: today.toJSDate(),
            },
            {
                label: '1 Year',
                selected: false,
                startDate: today.minus({ years: 1 }).toJSDate(),
                endDate: today.toJSDate(),
            },
        ],
    },
    warehouses: [
        {
            state: "MA",
            code: "01",
            postal_code: "02090",
        },
        {
            state: "IL",
            code: "33",
            postal_code: "",
        },
        {
            state: "FL",
            code: "11",
            postal_code: "",
        },
        {
            state: "NV",
            code: "22",
            postal_code: "",
        },
    ],
    topCategories: [
        {
            name: "Door Control",
            icon: "DoorClosersOperators",
        },
        {
            name: "Electric Strikes, Bolts, Magnets",
            icon: "ElectricStrikesBoltsMagnets",
        },
        {
            name: "Exit Devices",
            icon: "ExitDevices",
        },
        {
            name: "Locks, Cylinders, Cores, Keyblanks",
            icon: "LocksCylindersCoresKeyblanks",
        },
    ],
    allCategories: [
        {
            name: "Access Control",
            icon: "AccessControl",
            shortName: "Access Control"
        },
        {
            name: "Door Control",
            icon: "DoorClosersOperators",
            shortName: "Door Closers and Operators"
        },
        {
            name: "Electric Locks",
            icon: "ElectricLocks",
            shortName: "Electric Locks",
        },
        {
            name: "Electric Strikes, Bolts, Magnets",
            icon: "ElectricStrikesBoltsMagnets",
            shortName: "Electric Strikes, Bolts, Magnets",
        },
        {
            name: "Exit Devices",
            icon: "ExitDevices",
            shortName: "Exit Devices",
        },
        {
            name: "Intercoms",
            icon: "Intercoms",
            shortName: "Intercoms",
        },
        {
            name: "Hinges, Pivots and Hardware",
            icon: "HingesPivots",
            shortName: "Hinges and Pivots",
        },
        {
            name: "Integrated and Pushbutton",
            icon: "IntegratedPushbutton",
            shortName: "Integrated and Pushbutton",
        },
        {
            name: "Locks, Cylinders, Cores, Keyblanks",
            icon: "LocksCylindersCoresKeyblanks",
            shortName: "Locks, Cylinders, Cores, Keyblanks"
        },
        {
            name: "Power Supplies and Transformers",
            icon: "PowerSuppliesTransformers",
            shortName: "Power Supplies and Transformers",
        },
        {
            name: "Readers, Keypads, Credentials",
            icon: "ReadersKeypadsCredentials",
            shortName: "Readers, Keypads, Credentials",
        },
        {
            name: "Sensors, Switches, Relays, Timers",
            icon: "SensorsSwitchesRelaysTimers",
            shortName: "Sensors, Switches, Relays, Timers",
        },
        {
            name: "Tools",
            icon: "Tools",
            shortName: "Tools",
        },
    ],
    howHearOptions: [
        "I am an existing customer",
        "Search engine",
        "Referred",
        "Industry trade show",
        "Locksmith Ledger magazine",
        "Doors and Hardware magazine",
        "The National Locksmith magazine",
        "Keynotes magazine",
        "Glass magazine",
        "USGlass magazine",
        "SDM magazine",
        "Security Dealer &amp; Integrator magazine",
        "Trade organization",
        "Original Equipment Manufacturer (OEM)",
        "Other",
    ],
    phoneTypes: ["Company", "Direct", "Cell"],
    businessTypes: [
        "Hardware Store",
        "Contractor / Construction Co",
        "Contract Hardware",
        "Electrical / Elevator",
        "Access Control Integrator",
        "Glass / Fence",
        "Millwork / Door Fab",
        "Industrial Warehouse Supply",
        "Locksmiths",
        "Security",
        "Vendor",
        "Wholesale Distributor",
        "Lumber Company",
        "End User",
    ],
    search: {
        debounce: 700,
    },
    resources: {
        icons: {
            'Price Books': 'icon-collection-bookmark',
            'Catalogs': 'icon-catalogs-book-outline',
            'Parts': 'icon-developer-chip',
            'Parts Lists': 'icon-developer-chip',
            'Video': 'icon-movie',
            'Videos': 'icon-movie',
        }
    },
    pins: {
        icons: [
            {
                value: "Tray",
                icon: "icon-pin-tray",
            },
            {
                value: "Resource",
                icon: "icon-catalogs-book-outline",
            },
            {
                value: "Product",
                icon: "icon-products-outline",
            },
            {
                value: "Manufacturer",
                icon: "icon-manufacturers-outline",
            },
            {
                value: "Series",
                icon: "icon-resources-stack",
            },
        ],
        addToDestinations: [
            {
                value: "Shopping Cart",
                icon: "icon-shopping-cart",
                tray_route: "web.account.pins.add_tray_to_cart",
                item_route: "web.account.pins.add_item_to_cart",
            },

            //TODO: Add items to chat needs to be implemented. The route and the controller function are in place.
            // If this has to be done on the front end then we can adjust.
            // {
            //     value: "Chat",
            //     icon: "icon-chat-bubble",
            //     tray_route: "web.account.pins.add_tray_to_chat",
            //     item_route: "web.account.pins.add_item_to_chat",
            // },

            //TODO: Adding this option will require the use to select a project to add the products to
            // {
            //     value: "Project",
            //     icon: "icon-projects-outline",
            //     tray_route: "web.account.pins.add_tray_to_project",
            //     item_route: "web.account.pins.add_item_to_project",
            // },
        ],
    },
    intercomArticles: {
        finishes: {
            id: 7892451
        },
    },
    inStockVendors: [
        //add values as lower cased string
        "lcn",
        "von duprin",
        "schlage",
        "schlage electronics",
        "sargent",
    ],
    surcharges: {
        messages: {
            SURCHARGE: 'Tariff surcharge',
            SURCHARGE_PLURAL: 'Tariff surcharges',
            WAIVED: 'Tariff surcharge temporarily waved for this product',
            DEFAULT: 'Tariff surcharge may apply',
        }
    },
};
