<template>
    <p
        v-if="user && surcharge?.sc_type"
        class="flex items-center leading-5"
        :class="{
            'bg-gray-200 mb-[18px] px-[17px] py-[15px] text-sm mt-3 type-primary': !isSourcing,
            'sm-well-gray type-secondary p-4 text-sm mx-8 rounded': isSourcing
        }"
    >
        <BaseSvg
            class="w-6 mr-1 fill-gray"
            :class="{
                'dark:fill-white': isSourcing
            }"
            name="icon-info-outline"
        />
        
        <span v-if="surcharge.sc_amount > 0">{{ surchargeMessages.SURCHARGE }} of {{ surcharge.sc_type.toLowerCase() !== 'percent' ? '$' : '' }}{{ Number(surcharge.sc_amount).toFixed(2) }}{{ surcharge.sc_type.toLowerCase() === 'percent' ? '%' : '' }} applies</span>

        <span v-else-if="surcharge.sc_code.toLowerCase().includes('top') && surcharge.sc_amount === '0.0000'">{{ surchargeMessages.WAIVED }}</span>

        <span v-else>{{ surchargeMessages.DEFAULT }}</span>

        <a
            :href="route('web.catalog.products.show', productSlug)"
            class="ml-2 shrink-0"
            :class="{
                'text-blue-600 hover:underline': !isSourcing,
                'type-primary decoration-dotted underline underline-offset-4 hover:decoration-solid': isSourcing
            }"
            @click.stop.prevent="openArticle(10703654)"
            title="Surcharge info"
        >
            {{ $t('More info') }}
        </a>
    </p>
</template>

<script setup>
import { BaseSvg } from "@/Components";
import { useChat, useUser } from "@/Composables";
import config from "@/config";
import { inject } from "vue";

const props = defineProps({
    surcharge: {
        type: Object,
        required: true
    },
    productSlug: {
        type: String,
        required: true
    },
    isSourcing: {
        type: Boolean,
        required: false,
        default: false
    }
});

const { user } = useUser();
const { openArticle } = useChat();
const route = inject("route");
const surchargeMessages = config.surcharges.messages;
</script>